import { formatDate2, isDateAfterOrEqual, isDateBeforeOrEqual } from './date'
import {
  getDimensionTranslationFromTitle,
  getSourceValueDimensionTranslation
} from './explore'
import { hasEditorPermissionBySource } from './source'
import { useCubeStore } from '~/stores/cube'
import {
  SourceValueDimension,
  type NormalizedFieldsByDimension,
  type SourceValueDimensionOption,
  type SourceValueWithDimensionTitle,
  type SourceValueWithMissingNormalizedFields,
  type SourceValueWithNormalizedFields,
  type SourceValuesByDimension,
  type SourceValue,
  NormalizedFieldEntry,
  type NormalizationAnalyticsByFieldEntry
} from '~/types/sourceValue'

export const getSortedSourceValuesByDimension = <
  T extends SourceValueWithDimensionTitle
>(
  sourceValues: T[]
) => {
  const unsortedSourceValuesByDimension = sourceValues.reduce<{
    [key: string]: T[]
  }>(
    (
      sourceValuesByDimension: {
        [key: string]: T[]
      },
      sourceValueWithDimensionTitle: T
    ) => ({
      ...sourceValuesByDimension,
      [sourceValueWithDimensionTitle.dimension]: [
        ...(sourceValuesByDimension[sourceValueWithDimensionTitle.dimension] ??
          []),
        sourceValueWithDimensionTitle
      ]
    }),
    {}
  )

  return Object.entries(unsortedSourceValuesByDimension).reduce<{
    [key: string]: T[]
  }>(
    (
      sourceValuesByDimension: { [key: string]: T[] },
      [dimensionTitle, sourceValues]: [string, T[]]
    ) => ({
      ...sourceValuesByDimension,
      [dimensionTitle]: sortSourceValuesByLastUsedAt(sourceValues)
    }),
    {}
  )
}

export const getSourceValueDimensionOptions = (
  sourceValuesByDimension: SourceValuesByDimension,
  isNormalization: boolean = false
): SourceValueDimensionOption[] =>
  Object.values(SourceValueDimension)
    .filter(dimensionKey => sourceValuesByDimension[dimensionKey])
    .map(dimensionKey => {
      const dimensionTitle =
        sourceValuesByDimension[dimensionKey]![0]!.dimensionTitle

      return {
        label: dimensionTitle
          ? getDimensionTranslationFromTitle(dimensionTitle)
          : getSourceValueDimensionTranslation(dimensionKey, isNormalization),
        dimensionKey
      }
    })

const sortSourceValuesByLastUsedAt = <T extends SourceValueWithDimensionTitle>(
  sourceValues: T[]
) =>
  sourceValues.toSorted((sourceValue1, sourceValue2) => {
    if (!sourceValue1.lastUsedAt && !sourceValue2.lastUsedAt) return 0
    if (!sourceValue1.lastUsedAt) return -1
    if (!sourceValue2.lastUsedAt) return 1

    return (
      new Date(sourceValue2.lastUsedAt).getTime() -
      new Date(sourceValue1.lastUsedAt).getTime()
    )
  })

export const buildSourceValuesWithMissingNormalizedFields = (
  normalizedFieldsByDimension: NormalizedFieldsByDimension,
  sourceValues: SourceValueWithNormalizedFields[]
): SourceValueWithMissingNormalizedFields[] => {
  return sourceValues.map(sourceValue => ({
    ...sourceValue,
    normalizedFields: normalizedFieldsByDimension[sourceValue.dimension]!.map(
      normalizedFieldEntry => {
        const normalizedField = sourceValue.normalizedFields.find(
          normalizedField => normalizedField.field === normalizedFieldEntry
        )

        return normalizedField
          ? {
              id: normalizedField.id,
              field: normalizedField.field,
              normalizedValue: normalizedField.normalizedValue
            }
          : { normalizedValue: null, field: normalizedFieldEntry }
      }
    )
  }))
}

export const getSourceValueFullTitle = (sourceValue: SourceValue) => {
  const { $t } = useNuxtApp()
  return `${sourceValue.sourceValue}\n${$t(
    'sharedAnalytics.sourceValue.lastUsedAt',
    {
      date: sourceValue.lastUsedAt ? formatDate2(sourceValue.lastUsedAt) : '-'
    }
  )}`
}

export const hasEditorPermissionBySourceValue = (sourceValue: SourceValue) => {
  return hasEditorPermissionBySource(sourceValue.source.id)
}

export const filterEditableSourceValue = <T extends SourceValue>(
  sourceValues: T[],
  selectedSourceId: string | null,
  isEditable: boolean
) => {
  const editableSourceValues = sourceValues.filter(
    sourceValue => !isEditable || hasEditorPermissionBySourceValue(sourceValue)
  )

  return editableSourceValues.filter(
    sourceValue =>
      !selectedSourceId || sourceValue.source.id === selectedSourceId
  )
}

export const getEditableSourceValues = (
  sourceValuesByDimension: SourceValuesByDimension,
  dimensionKey: string
) => {
  return sourceValuesByDimension[dimensionKey]
    ? [
        ...sourceValuesByDimension[dimensionKey]!.map(sourceValue => ({
          ...sourceValue
        }))
      ]
    : []
}

export const getNormalizationFieldAnalytics = (
  sourceValuesByDimension:
    | {
        [key: string]: SourceValueWithNormalizedFields[]
      }
    | undefined,
  normalizedFieldsByDimension: NormalizedFieldsByDimension | undefined
): NormalizationAnalyticsByFieldEntry | undefined => {
  if (!normalizedFieldsByDimension || !sourceValuesByDimension) return undefined

  return Object.values(NormalizedFieldEntry).reduce(
    (acc, normalizedFieldEntry) => {
      const associatedDimension = Object.keys(normalizedFieldsByDimension).find(
        dimension =>
          normalizedFieldsByDimension[dimension]?.includes(normalizedFieldEntry)
      )!

      const valuesTotal =
        sourceValuesByDimension[associatedDimension]?.length || 0

      const normalizedValuesTotal =
        sourceValuesByDimension[associatedDimension]?.filter(value =>
          value.normalizedFields.some(
            field => field.field === normalizedFieldEntry
          )
        ).length || 0

      acc[normalizedFieldEntry] = {
        normalizedValuesTotal,
        valuesTotal
      }
      return acc
    },
    {} as NormalizationAnalyticsByFieldEntry
  )
}

export const getUniqueImpactedMembers = (
  normalizedFieldEntry: NormalizedFieldEntry
): { measures: string[]; dimensions: string[] } => {
  const { measuresMeta, dimensionsMeta } = useCubeStore()

  return {
    measures: measuresMeta
      ? measuresMeta
          .filter(
            m =>
              m.isVisible &&
              m.meta.normalizationFieldEntries?.includes(normalizedFieldEntry)
          )
          .map(m => m.shortTitle)
      : [],
    dimensions: dimensionsMeta
      ? [
          ...new Set(
            dimensionsMeta
              .filter(
                d =>
                  d.isVisible &&
                  d.meta.normalizationFieldEntries?.includes(
                    normalizedFieldEntry
                  )
              )
              .map(d => d.shortTitle)
          )
        ]
      : []
  }
}

export const filterSourceValuesByUsedDates = <
  T extends SourceValueWithDimensionTitle
>(
  sourceValues: T[],
  dateRange: [Date, Date]
): T[] => {
  return sourceValues.filter(sourceValue => {
    const firstUsedAt = sourceValue.firstUsedAt
      ? new Date(sourceValue.firstUsedAt)
      : null

    const lastUsedAt = sourceValue.lastUsedAt
      ? new Date(sourceValue.lastUsedAt)
      : null

    return (
      (!firstUsedAt || isDateBeforeOrEqual(firstUsedAt, dateRange[1])) &&
      (!lastUsedAt || isDateAfterOrEqual(lastUsedAt, dateRange[0]))
    )
  })
}

export const convertSourceValuesListToMapById = <
  T extends { id: string } & Partial<SourceValue>
>(
  sourceValues: T[]
): Map<string, T> => {
  return new Map(sourceValues.map(sourceValue => [sourceValue.id, sourceValue]))
}
